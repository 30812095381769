import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";

import { localeDict } from 'widget/companyDef';
import { setLocale } from 'store/actions/intl';
import Link from 'components/Link/Link';

const hostname = (process.env.BROWSER && global && global.window && global.window.location.hostname) || '';
const localeName = locale => localeDict[locale] || locale;

class ChgLocale extends Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // this.state = {
    //   typeId: '1',
    //   companies: []
    // };
  }

  // async componentDidMount() {
  //   const companies = await Parse.queryData('company')
  //   this.setState({ companies })
  // }

  render() {
    const { availableLocales, setLocale } = this.props; //companyObj
    return (<>
      {hostname === 'localhost' ?
        <UncontrolledDropdown nav inNavbar className="btn-rotate">
          <DropdownToggle color="default" caret nav>
            <i className="fa fa-globe" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-danger" right>
            {availableLocales.map((locale, index) => (
              <DropdownItem
                key={`ddi${index}`}
                onClick={e => {
                  setLocale({ locale });
                  e.preventDefault();
                }} to='#' tag={Link}>
                {/* <i className="nc-icon nc-bank" /> */}
                {localeName(locale)}
              </DropdownItem>))}
          </DropdownMenu>
        </UncontrolledDropdown> : null}
    </>);
  }
}

const mapState = state => ({
  // user: state.user,
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  // setCompany,
  // logout,
  setLocale,
};

export default connect(mapState, mapDispatch)(ChgLocale);
